import React, { useEffect } from "react"

const RefundPolicy = () => {

  useEffect(()=>{
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('trackCustom', 'Payment Successful');
      }
    }
  }, [])

  return(
    <div className="hero is-fullheight-with-navbar is-large has-text-centered">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">
            Refund Policy
          </h1>
          <div className="content is-medium">
          <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque blandit laoreet sapien a bibendum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vehicula hendrerit massa at tincidunt. Ut commodo rutrum purus, nec finibus justo ultrices sit amet. Donec quis tristique metus. Maecenas quis elit sed est interdum posuere. Donec sed turpis malesuada, fringilla enim id, mollis mauris. Maecenas enim massa, efficitur in rhoncus quis, cursus vel libero. Phasellus rutrum consectetur turpis, vel fermentum lorem iaculis a. Integer eu semper urna, sit amet convallis magna. Suspendisse sit amet semper leo. Duis non velit sit amet justo dictum congue nec in arcu.

                Donec tristique eros at mi ornare tempus. Praesent justo erat, mattis at tincidunt vel, pharetra id tortor. Vestibulum mi libero, tempus sed ipsum ut, ornare condimentum lacus. Nam vel dictum ante. Nullam bibendum malesuada fermentum. Praesent feugiat vel arcu vitae malesuada. Vivamus dapibus quam non purus feugiat, varius ultricies leo molestie. Nam eget dictum sapien.
              </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RefundPolicy