import React, { useEffect } from "react"

const Thankyou = () => {

  useEffect(()=>{
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('trackCustom', 'Payment Successful');
      }
    }
  }, [])

  return(
    <div className="hero is-fullheight-with-navbar is-large has-text-centered">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">
            📫 😃
            <br />
            Thanks for Purchasing Beatshine!
          </h1>
          <div className="content is-medium">
            <p>
              Your Beatshine Account has been activated.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Thankyou