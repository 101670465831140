import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ReactNotification from 'react-notifications-component'
import Thankyou from "../../components/Thankyou"
import RefundPolicy from "../../components/RefundPolicy"

const RefundPolicyPage = () => {
  return(
    <Layout>
      <ReactNotification />
      <SEO title="Refund Policy" path="/refund_policy"/>
      <RefundPolicy />
    </Layout>
  )
}

export default RefundPolicyPage
